import './App.css';
import React, { useState, useEffect } from 'react';
import { Amplify, Storage } from 'aws-amplify';
import awsExports from './aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure(awsExports);

Storage.configure({
  customPrefix: {
    public: '',
    protected: '',
    private: ''
  }
})

let urlCache = {}

function isImage(s3Item) {
  let imgExt = ["JPG", "JPEG", "PNG", "GIF", "SVG"]
  let parts = s3Item.name.toUpperCase().split('.')
  if (parts.length < 2)
    return false
  return imgExt.indexOf(parts[parts.length - 1]) !== -1
}

function SignOutButton(props) {
  if (props.user != null) {
    return <div className="sign-out-button">
      <span>{props.user.attributes.email}</span>
      <button onClick={props.signOut}>Sign Out</button>
    </div>
  }
  return <div className="sign-out-button"></div>
}

function Breadcrumb(props) {
  let handleClick = (e) => {
    props.onClick(props.path)
  }

  return <span><span className="breadcrumb" onClick={handleClick}>{props.label}</span><span>&nbsp;/&nbsp;</span></span>
}

function Breadcrumbs(props) {
  let path = ""
  let crumbs = [
    { label: "Root", path: path }
  ]
  for (let folder of props.path.split('/')) {
    if (folder !== "") {
      path += folder + "/"
      crumbs.push({ label: folder, path: path })
    }
  }

  function handleCrumbClick(destPath) {
    props.onBreadcrumbClick(destPath)
  }

  return ( <div className="breadcrumbs">
    <span>/&nbsp;</span>
    {crumbs.slice(0, -1).map(c => <Breadcrumb key={c.path} label={c.label} path={c.path} onClick={handleCrumbClick}/>)}
    <span>{crumbs[crumbs.length-1].label}</span>
  </div> )
}

function Folder(props) {
  return (<li onClick={e => {
    props.onClick(props.element)
    e.preventDefault()
  }}>
    {props.element.name}
  </li>)
}

function Folders(props) {
  function onFolderClick(f) {
    props.onFolderClick(f)
  }

  return (<ul className="folders">
    {
      props.folders.map(e => {
        return <Folder key={e.key} element={e} onClick={onFolderClick}/>
      })
    }
  </ul>)
}

function ListDocument(props) {
  const [url, setUrl] = useState(null)

  useEffect(() => {
    if (urlCache[props.element.key] == null) {
      Storage.get(props.element.key).then(url => {
        urlCache[props.element.key] = url
        setUrl(url)
      })
    } else {
      setUrl(urlCache[props.element.key])
    }
  }, [props.element.key])

  if (url != null) {
    if (isImage(props.element)) {
      return ( <div className="chop-image"><img src={url} alt={props.element.name}/></div >)
    } else {
      return (
        <div className="chop-image">
          <a href={url} target="_blank" rel="noopener noreferrer">{props.element.name}</a>
        </div >
      )
    }
  }
  return <div className="chop-image">{props.element.name}</div>
}

function Files(props) {
  return (<div className="files">
    {props.files.map(e => <ListDocument key={e.key} element={e}/>)}
  </div>)
}

function parseFile(name, obj, path, hierarchy) {
  if (name === "")
    return
  const pos = name.indexOf("/")
  const subName = name.substring(0, pos === -1 ? name.length : pos)
  let hierarchyPos;
  for (hierarchyPos = 0; hierarchyPos < hierarchy.length; hierarchyPos++) {
    if (hierarchy[hierarchyPos].name === subName)
      break
  }
  
  // If this is a folder.
  if (pos !== -1) {
    if (hierarchyPos === hierarchy.length)
      hierarchy.push({ name: subName, key: path + subName + "/", contents: [] })
    parseFile(name.substring(pos+1), obj, path + subName + "/", hierarchy[hierarchyPos].contents)
  } else {
    if (hierarchyPos === hierarchy.length) {
      hierarchy.push({
        name: subName,
        key: obj.key,
        size: obj.size,
        lastModified: obj.lastModified,
        url: null
      })
    }
  }
}

function sortHierarchy(hierarchy) {
  for (let item of hierarchy) {
    if (item.contents != null) {
      sortHierarchy(item.contents)
    }
  }
  hierarchy.sort((a, b) => {
    if (a.contents == null && b.contents != null)
      return 1;
    if (a.contents != null && b.contents == null)
      return -1;
    
    if (a.contents != null) {
      // Folder comparison is done by name.
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB)
        return -1;
      if (nameB > nameA)
        return 1;
    } else {
      // Sort files from most recent to least recent
      if (a.lastModified < b.lastModified)
        return -1;
      if (a.lastModified > b.lastModified)
        return 1;
    }

    return 0;
  })
}

function App(props) {
  const [hierarchy, setHierarchy] = useState([])
  const [folder, setFolder] = useState([])
  const [path, setPath] = useState("")

  function parseListToHierarchy(files) {
    let hierarchy = []
    for (const f of files) {
      parseFile(f.key, f, "", hierarchy)
    }
    sortHierarchy(hierarchy)
    return hierarchy
  }

  function navigateToPath(path) {
    let pathElements = path.split('/')
    let newFolder = hierarchy;
    let currentPath = ""
    for (const pathElement of pathElements) {
      if (pathElement !== "") {
        let filtered = newFolder.filter(e => e.name === pathElement)
        if (filtered.length !== 1)
          break
        newFolder = filtered[0].contents
        currentPath += pathElement + "/"
      }
    }
    setFolder(newFolder)
    setPath(currentPath)
  }

  function navigateToFolder(f) {
    setFolder(f.contents)
    setPath(f.key)
  }

  async function loadFromStorage() {
    try {
      let result = await Storage.list('')
      let parsed = parseListToHierarchy(result)
      setHierarchy(parsed)
      setFolder(parsed)
      setPath("")
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    loadFromStorage()
  }, [])

  const folders = folder.filter(e => e.contents != null)
  const files = folder.filter(e => e.contents == null)

  return (
    <div className="App">
      <SignOutButton signOut={props.signOut} user={props.user}/>
      <h1>MatrixSpec's Images</h1>
      <Breadcrumbs path={path} onBreadcrumbClick={navigateToPath}/>
      <Folders folders={folders} onFolderClick={navigateToFolder}/>
      <Files files={files}/>
    </div>
  );
}

export default withAuthenticator(App, {hideSignUp: true});
